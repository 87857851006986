<template>
  <!-- begin::MyCompanySubscription edit for TransporterAdmin & SenderAdmin Roles -->
  <div>
    <div class="white-box shadow-box">
      <div class="manager-subtitle">
        {{ $t("COMPANIES.SUBSCRIPTION_TYPE") }}
      </div>

      <v-form
        ref="formChange"
        v-model="FormChange.Valid"
        lazy-validation
        class="form"
      >
        <!-- begin::SubscriptionOptions -->
        <div class="row">
          <div
            v-for="st in SubscriptionOptions"
            :key="st.SubscriptionTypeID"
            class="col-lg-6"
          >
            <div class="primary-box">
              <div class="primary-box-header bg-transparent">
                <div class="row">
                  <div class="col-lg-6">
                    <v-radio-group
                      v-model="FormChange.Fields.SubscriptionTypeID"
                      row
                      style="margin-bottom: -2.5em"
                    >
                      <v-radio :value="st.SubscriptionTypeID" class="ml-6">
                        <template v-slot:label>
                          <h4 class="mb-0 pt-2 ml-3 text-primary">
                            <strong>
                              {{ st.Name }}
                            </strong>
                          </h4>
                        </template>
                      </v-radio>
                    </v-radio-group>
                  </div>
                  <div class="col-lg-6 text-right text-primary">
                    <h4
                      class="mb-0 pt-4 pr-6"
                      v-html="
                        formatSubscriptionPrice(st.PricePerMonth, st.Months)
                      "
                    ></h4>
                  </div>
                </div>
              </div>
              <div class="primary-box-body">
                <ul>
                  <li
                    v-for="(descLine, d) in st.Description"
                    :key="'Descri_' + st.SubscriptionTypeID + '_' + d"
                  >
                    {{ descLine }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <!-- end::SubscriptionOptions -->

        <!-- begin::Legal -->
        <div v-if="hasChangedType" class="pl-1 mt-6 mb-3">
          <div class="search-info mb-3">
            <inline-svg
              src="/media/icons/help-alt-5-success.svg"
              class="mx-3"
            />
            {{ $t("MY_COMPANY.INFO_CHANGE") }}
          </div>

          <div v-if="requirePayment" class="search-info mb-3">
            <inline-svg
              src="/media/icons/help-alt-5-success.svg"
              class="mx-3"
            />
            {{ $t("MY_COMPANY.INFO_PAY") }}
          </div>

          <v-checkbox
            v-model="FormChange.Fields.Accept"
            :required="hasChangedType"
            :rules="hasChangedType ? [rules.required] : []"
          >
            <template v-slot:label>
              <div class="pt-1 ml-3 text-dark">
                {{ $t("GENERIC.ACCEPT_CONDITIONS_1") }}
                <a
                  href="/legal/terms"
                  target="_blank"
                  @click.stop
                  class="font-weight-bolder"
                >
                  {{ $t("GENERIC.ACCEPT_CONDITIONS_2") }}
                </a>
              </div>
            </template>
          </v-checkbox>
        </div>
        <!-- end::Legal -->

        <!-- begin::Action buttons -->
        <div class="row button-row">
          <button
            class="btn btn-pill btn-white button-form button-form-primary"
            @click="onClickBackButton"
          >
            <inline-svg
              src="/media/icons/arrow-return-primary.svg"
              class="mr-3"
            />
            {{ $t("GENERIC.BACK") }}
          </button>

          <button
            v-if="hasChangedType"
            class="btn btn-pill btn-tertiary button-form ml-9"
            :disabled="
              !FormChange.Valid || (hasChangedType && !FormChange.Fields.Accept)
            "
            @click="onSubmitForm"
          >
            {{ $t("MY_COMPANY.CONFIRM_SUBSCRIPTION") }}
          </button>
        </div>
        <!-- end::Action buttons -->
      </v-form>
    </div>
  </div>
  <!-- end::MyCompanySubscription edit for TransporterAdmin & SenderAdmin Roles -->
</template>

<script>
import Swal from "sweetalert2";

import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME
} from "@/core/services/store/htmlclass.module.js";
import { UNDO_AUTH } from "@/core/services/store/auth.module";

import SubscriptionService from "@/core/services/api/v2/subscription.service";
import SubscriptionTypeService from "@/core/services/api/v2/subscriptiontype.service";

export default {
  name: "MyCompanySubscriptionAdmin",

  computed: {
    hasChangedType() {
      let selectedSTID = this.FormChange.Fields.SubscriptionTypeID;
      return selectedSTID !== this.CurrentSubscriptionTypeID;
    },

    requirePayment() {
      if (!this.hasChangedType) {
        return false;
      } else {
        let selectedSTID = this.FormChange.Fields.SubscriptionTypeID;
        let selectedPrice = 0;
        this.SubscriptionOptions.forEach(s => {
          if (s.SubscriptionTypeID === selectedSTID) {
            selectedPrice = s.PricePerMonth;
          }
        });

        return selectedPrice > 0;
      }
    }
  },

  data() {
    return {
      SubscriptionOptions: [],
      CurrentSubscriptionTypeID: 0,
      FormChange: {
        Valid: true,
        Fields: {
          SubscriptionTypeID: 0,
          Accept: false
        }
      },
      rules: {
        required: value => !!value || this.$i18n.t("VALIDATION.REQUIRED_FIELD")
      }
    };
  },

  mounted() {
    this.loadMySubscriptionData();
  },

  methods: {
    loadMySubscriptionData() {
      // Show page loader
      this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");

      this.SubscriptionOptions = [];

      // Pick current Subscription vs alternatives (if any)
      SubscriptionService.getSubscriptionProfileMyCompany().then(response1 => {
        if (response1 !== 404) {
          this.CurrentSubscriptionTypeID = this.FormChange.Fields.SubscriptionTypeID =
            response1.SubscriptionTypeID;
          let currentPricePerMonth = response1.PricePerMonth;
          let currentMonths = response1.Months;

          SubscriptionTypeService.listAll(response1.CompanyTypeID).then(
            typesList => {
              typesList.forEach(st => {
                // If current, overwrite default price&months
                let stPricePerMonth = st.PricePerMonth;
                let stMonths = st.Months;

                if (st.SubscriptionTypeID === response1.SubscriptionTypeID) {
                  stPricePerMonth = currentPricePerMonth;
                  stMonths = currentMonths;
                }

                this.SubscriptionOptions.push({
                  SubscriptionTypeID: st.SubscriptionTypeID,
                  Name: st.Name,
                  Description: st.Description,
                  PricePerMonth: stPricePerMonth,
                  Months: stMonths
                });
              });

              // Remove page loader
              this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");

              // If there are no alternative types, there's nothing to do here
              if (this.SubscriptionOptions.length < 2) {
                this.onClickBackButton();
              }
            }
          );
        }
      });
    },

    formatSubscriptionPrice(pricePerMonth, months) {
      let price = parseFloat(pricePerMonth);

      if (price === 0) {
        return "<strong>" + this.$t("GENERIC.PRICE_FREE") + "</strong>";
      } else {
        price = price * months;
        return (
          "<strong>" +
          price.toFixed(2).replace(".", ",") +
          " €</strong> / " +
          this.$tc("SUBSCRIPTION_TYPES.PER_MONTHS", months, { count: months })
        );
      }
    },

    onClickBackButton() {
      this.FormChange.Fields.SubscriptionTypeID = 0;
      this.$router.push("/manager/myprofile?ActiveTab=basic");
    },

    onSubmitForm(e) {
      e.preventDefault();

      if (this.$refs.formChange.validate()) {
        // Disable submit button
        this.FormChange.Valid = false;

        // Send update request:
        SubscriptionService.updateSubscriptionProfileMyCompany(
          this.FormChange.Fields
        ).then(rawResponse => {
          if (Number.isInteger(rawResponse)) {
            // Something went wrong
            let whyError = this.$i18n.t("MY_COMPANY.ALERT_CHANGE_KO");
            this.$bvToast.toast(whyError, {
              title: this.$i18n.t("GENERIC.SAVE_ERROR"),
              variant: "danger",
              solid: true
            });

            // Reset submit button
            this.FormChange.Valid = true;
          } else {
            // Correcly changed: must pay?
            let paymentUrl = rawResponse.PaymentUrl;
            if (paymentUrl.length > 0) {
              window.location.href = paymentUrl;
            } else {
              Swal.fire({
                text: this.$i18n.t("MY_COMPANY.ALERT_CHANGE_OK"),
                icon: "success",
                confirmButtonText: this.$i18n.t("GENERIC.CLOSE"),
                customClass: {
                  confirmButton: "btn btn-tertiary btn-lg font-weight-bolder"
                },
                buttonsStyling: false,
                focusConfirm: false
              });

              // Must reload suscription features, so logout
              this.$store.dispatch(UNDO_AUTH);
              this.$router.push({ name: "Login" });
            }
          }
        });
      }
    }
  }
};
</script>
